<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          ref="builder-tab"
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
              @click="setActiveTab"
            >
              User
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs v-model="tabIndex" class="hide-tabs">
            <b-tab active>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  User Email:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <b-form-group
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                  >
                    <b-form-input
                      id="example-input-1"
                      v-model="$v.form.email.$model"
                      class="form-control form-control-solid h-auto py-5 px-6"
                      name="example-input-1"
                      :state="validateState('email')"
                      aria-describedby="input-1-live-feedback"
                      placeholder="Email address"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Email is required and a valid email address.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Password:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <b-form-group
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                  >
                    <b-form-input
                      id="example-input-2"
                      v-model="$v.form.password.$model"
                      class="form-control form-control-solid h-auto py-5 px-6"
                      type="password"
                      name="example-input-2"
                      :state="validateState('password')"
                      aria-describedby="input-2-live-feedback"
                      placeholder="Password"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-2-live-feedback">
                      Password is required.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <button
                ref="kt_login_signup_submit"
                type="submit"
                class="btn btn-primary font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </b-form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";
import { UPDATE_USER } from "@/core/services/store/auth.module";

export default {
  name: "Profile",
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      form: {
        email: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "currentUser"
    ]),

    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    this.form.email = this.currentUser.email;
    // set the tab from previous
    this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    /**
     * Reset config
     */
    reset(event) {
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("config");
      window.location.reload();
    },

    /**
     * Set previous tab active
     */
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },

    /**
     * Submit form
     * @param event
     */
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        this.$store
          .dispatch(UPDATE_USER, {
            email: email,
            password: password
          })
          .then(() => {
            window.location.reload();
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  }
};
</script>
